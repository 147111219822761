import React from 'react';

import { navigate } from 'gatsby';

import { useAuth } from '@context';

export const IsGestor = ({ children }) => {
  const auth = useAuth();

  if (!auth?.userInfo) {
    return <div />;
  }

  if (auth?.userInfo.selected === 1) {
    navigate('/');
    return <div />;
  }

  return children;
};

import React from 'react';
import { navigate } from 'gatsby';

import { useDevice } from '@components/utils';

import { useAuth } from '@context';

export const IsDesktop = ({ children }) => {
  const { isDesktop } = useDevice();
  const { userInfo } = useAuth();

  if (isDesktop() && userInfo.selected === 0) {
    navigate('/desktop');
    return <div />;
  }

  return children;
};
